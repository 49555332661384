<template>
  <div id="Contact">
    <div class="header">
      <span>CONTACT</span>
      <h2>聯絡我們</h2>
    </div>

    <div class="content">
      <p class="info">
        請填寫有關產品問題、售後服務或購買相關的問題等必要訊息，<br />
        您可以先查閱<router-link to="/support">常見問題</router-link
        >查看產品相關問題的答覆。
      </p>
      <form>
        <div class="form_group">
          <p>
            訊息內容<span :class="GetError('comment')">請輸入訊息內容</span>
          </p>
          <textarea v-model="form_data.comment"></textarea>
        </div>
        <div class="form_group">
          <p>姓名<span :class="GetError('name')">請輸入姓名</span></p>
          <input v-model="form_data.name" type="text" name="name" />
        </div>
        <div class="form_group">
          <p>電話<span :class="GetError('phone')">請輸入電話</span></p>
          <input v-model="form_data.phone" type="text" name="phone" />
        </div>
        <div class="form_group">
          <p>電子信箱<span :class="GetError('email')">請輸入電子信箱</span></p>
          <input v-model="form_data.email" type="text" name="email" />
        </div>
      </form>
      <div class="footer">
        <button @click="FormValidate" class="back_button">
          送出訊息<svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="long-arrow-alt-right"
            class="svg-inline--fa fa-long-arrow-alt-right fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import data_valid from '@/common/data_valid.js';
export default {
  name: 'Contact',
  mixins: [data_valid],
  data() {
    return {
      errors: [],
      status: 'wait',
      form_data: {
        comment: '',
        name: '',
        email: '',
        phone: '',
      },
    };
  },
  methods: {
    FormValidate() {
      this.errors = [];
      if (this.validEmail(this.form_data.email) != true) {
        this.errors.push('email');
      }
      if (this.validPhone(this.form_data.phone) != true) {
        this.errors.push('phone');
      }
      if (this.validName(this.form_data.name) != true) {
        this.errors.push('name');
      }
      if (this.validNotEmpty(this.form_data.comment) != true) {
        this.errors.push('comment');
      }

      if (this.errors.length <= 0) {
        if (this.status != 'sending') {
          this.status = 'sending';
          this.SendMail();
        }
      }
    },
    async SendMail() {
      this.status = 'sending';
      let data = {
        user: {
          name: this.form_data.name,
          phone: this.form_data.phone,
          email: this.form_data.email,
          comment: this.form_data.comment,
        },
      };

      let result = await this.SendPostData(
        process.env.VUE_APP_BASE_API + 'mail/create_contact.php',
        qs.stringify(data)
      );
      if (result != 'error') {
        this.status = 'wait';
        this.$store.commit('SetDialog', {
          title: '訊息已送出',
          content:
            '我們會盡快與您聯絡，若有任何問題您也可以至粉絲專頁或使用電話與我們聯絡獲得更即時的服務。',
          status: true,
        });
      }
    },
    GetError(key) {
      let error = this.errors.filter((item) => item == key);
      return error.length > 0 ? 'error' : '';
    },
  },
  created() {
    this.$nextTick(() => {
      window.prerenderReady = true;
    });
  },

  metaInfo() {
    return {
      title: '聯絡我們｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '有任何產品問題、經銷配合、訂單相關的疑問歡迎來電或來訊與我們聯絡',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '有任何產品問題、經銷配合、訂單相關的疑問歡迎來電或來訊與我們聯絡',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '有任何產品問題、經銷配合、訂單相關的疑問歡迎來電或來訊與我們聯絡',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '聯絡我們｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '聯絡我們｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '聯絡我們｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
